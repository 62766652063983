import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faStackOverflow, faGithub, faLinkedin, faBloggerB,
} from '@fortawesome/free-brands-svg-icons';

export const about = ['Programmer', 'Experimentalist', 'Engineer'];

export const social = [{
  mail: true, title: 'Email', icon: faEnvelope, url: 'ashik9591@gmail.com',
},
{
  mail: false, title: 'Github', icon: faGithub, url: 'https://github.com/ashikmeerankutty',
},
{
  mail: false, title: 'StackOverflow', icon: faStackOverflow, url: 'https://stackoverflow.com/users/8122821/ashik-meerankutty',
},
{
  mail: false, title: 'LinkedIn', icon: faLinkedin, url: 'https://linkedin.com/in/ashikmeerankutty',
},
{
  mail: false, title: 'Blog', icon: faBloggerB, url: 'https://blog.driftbyte.xyz',
},
];
