import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import profile from '../../assets/images/about_img.jpg';
import { social } from '../../utils/constants';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dark: false,
    };
  }

  render() {
    const { dark } = this.state;
    return (
      <div className={dark ? 'home dark' : 'home'}>
        <div className="profile_holder">
          <div className="hex">
            <div className="hex-inside">
              <div className="hex-image">
                <img className="profile_img" src={profile} alt="Profile" />
              </div>
            </div>
          </div>
          <h1 className="heading">Ashik Meerankutty</h1>
        </div>
        {/* <div className="about_list">
          {
            about.map((abt, index) => (
              <div key={abt} className="about">
                {index !== about.length - 1 ? (
                  <h4>
                    {abt}
                    {' '}
                    |
                  </h4>
                ) : <h4>{abt}</h4>}
              </div>
            ))
          }
        </div> */}
        <div className="social_icons">
          {social.map((soc) => (
            <div key={soc.title} className="icons">
              {soc.mail ? (
                <a href={`mailto:${soc.url}`}>
                  <FontAwesomeIcon
                    className={dark ? 'light_icon' : 'dark_icon'}
                    icon={soc.icon}
                  />
                </a>
              ) : (
                <a href={soc.url}>
                  <FontAwesomeIcon
                    className={dark ? 'light_icon' : 'dark_icon'}
                    icon={soc.icon}
                  />
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Home;
