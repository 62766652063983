import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/home';
import Privacy from './components/privacy';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: [{ path: '/', component: Home }, { path: '/postbot/privacy', component: Privacy }],
    };
  }

  render() {
    const { routes } = this.state;
    return (
      <Router>
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.path}
              exact
              path={`${route.path}`}
              component={route.component}
            />
          ))}
        </Switch>
      </Router>
    );
  }
}

export default Routes;
